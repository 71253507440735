import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Theme, css } from '@emotion/react';
import isPropValid from '@emotion/is-prop-valid';
import { Anchor, ClickEvent } from '@sumup/circuit-ui';
import { ChevronDown } from '@sumup/icons';

import { ExternalLink, MenuItem as MenuItemType } from '../../interfaces';

import LoginIcon from './svg/login.svg';

import Link from '~/shared/components/Link';
import useOptimizelyData from '~/shared/services/optimizely/use-optimizely-data';
import { getVariationComponent } from '~/shared/services/optimizely/OptimizelyVariationsService';
import { Event as OptimizelyEvent } from '~/shared/services/optimizely/types';

const anchorStyles = ({
  theme,
}: {
  theme: Theme;
  highlighted?: boolean;
}) => css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 3;
  cursor: pointer;
  text-decoration: none;
  color: var(--cui-fg-normal);
  font-size: ${theme.typography.body.one.fontSize};
  font-weight: ${theme.fontWeight.regular};
  width: 100%;

  &:hover,
  &:focus {
    text-decoration: underline;
  }

  &:active {
    text-decoration: underline;
  }

  &:visited {
    color: currentColor;

    &:hover,
    &:focus {
      color: var(--cui-fg-accent-hovered);
    }

    &:active {
      color: var(--cui-fg-accent-pressed);
    }
  }

  ${theme.mq.giga} {
    padding: 0;
    height: 100%;
    width: auto;
  }
`;

const anchorCurrentStyles = ({
  theme,
  ...props
}: {
  theme?: Theme;
  highlighted?: boolean;
  isCurrent?: boolean;
}) =>
  (props['aria-current'] === 'page' || props.isCurrent) &&
  css`
    font-weight: ${theme.fontWeight.bold};
  `;

const anchorHighlightedStyles = ({
  theme,
  ...props
}: {
  theme?: Theme;
  highlighted?: boolean;
}) =>
  props.highlighted &&
  css`
    font-size: ${theme.typography.headline.two.fontSize};
    font-weight: ${theme.fontWeight.bold};
    color: var(--cui-fg-normal);

    &:visited,
    &:hover,
    &:focus,
    &:active {
      color: currentColor;
    }
  `;

const StyledAnchor = styled(Anchor)(
  anchorStyles,
  anchorCurrentStyles,
  anchorHighlightedStyles,
);

const loginIconStyles = ({ theme }: { theme: Theme }) => css`
  margin-right: ${theme.spacings.byte};
  fill: currentColor;
`;
const StyledLoginIcon = styled(LoginIcon)(loginIconStyles);

const arrowIconStyles = () => css`
  transition:
    transform 0.1s ease-in-out,
    color 0.1s ease-in-out;
`;
const arrowIconRotatedStyles = ({
  rotateArrowIcon,
}: {
  rotateArrowIcon?: boolean;
}) =>
  rotateArrowIcon &&
  css`
    transform: rotate(180deg);
  `;
export const StyledArrowIcon = styled(ChevronDown, {
  shouldForwardProp: isPropValid,
})(arrowIconStyles, arrowIconRotatedStyles);

export interface MenuItemProps extends MenuItemType {
  showPrefixIcon?: boolean;
  showArrowIcon?: boolean;
  rotateArrowIcon?: boolean;
  disableSPANavigation?: boolean;
  isCurrent?: boolean;
  highlighted?: boolean;
  onClick?: (_event?: ClickEvent) => void;
  optimizelyFullStackClickEvents?: string[] | OptimizelyEvent[];
  shouldTrack?: boolean;
}

export const MenuItem: FC<MenuItemProps> = ({
  id,
  contentType,
  image,
  name,
  label,
  description,
  destination: originalDestination,
  showPrefixIcon = false,
  showArrowIcon = false,
  rotateArrowIcon = false,
  disableSPANavigation = false,
  highlighted = false,
  onClick = () => {},
  optimizelyFullStackClickEvents = [],
  shouldTrack = true,
  menuItemGroups,
  ...rest
}) => {
  const { experiments } = useOptimizelyData();

  if (!(label || label?.length)) {
    return null;
  }

  const destination = getVariationComponent(
    originalDestination,
    experiments,
  ) as ExternalLink;
  const newTabProps =
    destination && 'forceNewTab' in destination && destination.forceNewTab
      ? {
          target: '_blank',
          rel: 'noopener noreferrer',
          disableSPANavigation: true,
        }
      : {};

  return (
    <Link
      href={destination?.url}
      onClick={onClick}
      disableSPANavigation={disableSPANavigation}
      trackingContentEntry={{
        contentType,
        contentEntryName: name,
        contentEntryId: id,
      }}
      trackingId=""
      optimizelyFullStackClickEvents={optimizelyFullStackClickEvents}
      shouldTrack={shouldTrack}
      {...newTabProps}
    >
      <StyledAnchor highlighted={highlighted} {...rest}>
        {showPrefixIcon && (
          <StyledLoginIcon aria-hidden="true" role="presentation" />
        )}
        <span>{label}</span>
        {showArrowIcon && (
          <StyledArrowIcon
            aria-hidden="true"
            role="presentation"
            rotateArrowIcon={rotateArrowIcon}
          />
        )}
      </StyledAnchor>
    </Link>
  );
};
