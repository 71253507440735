import { IElbwalker, WebDestination } from '@elbwalker/walker.js';

declare namespace DestinationSumUpGTM {
  interface Function
    extends WebDestination.Function<CustomConfig, CustomEventConfig> {}

  type Config = WebDestination.Config<CustomConfig, CustomEventConfig>;

  interface CustomConfig {}

  interface CustomEventConfig {}
}

export const destinationSumUpGTM: DestinationSumUpGTM.Function = {
  config: {},

  init() {
    window.dataLayer = window.dataLayer || [];

    return true;
  },

  push(event) {
    const { globals } = event;

    const commonProperties = {
      event_timestamp_msec: event.timestamp,
      user_agent: navigator.userAgent,
    };

    const eventMapping = customEventMapping(event);

    if (!eventMapping) {
      // eslint-disable-next-line no-console
      console.warn(
        'You are trying to send GA4 event that is not properly mapped to schema.',
        event,
      );
      return;
    }

    const { eventBody, eventName } = eventMapping;
    const finalEvent = {
      event: eventName,
      ...commonProperties,
      ...globals,
      ...eventBody,
      walker: true,
    };

    window.dataLayer.push(finalEvent);
  },
};

export function customEventMapping(event: IElbwalker.Event) {
  // Rename events to snake_case
  const eventName = event.event.replace(/ /g, '_').toLowerCase();

  switch (eventName) {
    case 'page_view':
    case 'button_clicked':
    case 'optimizely-experiment_activated':
    case 'optimizely-user-id_assigned':
    case 'optimizely-feature_decided':
    case 'generate_lead':
    case 'generate-lead-validation_failed':
    case 'country_selected':
    case 'video_started':
    case 'video-progress_reached':
    case 'video_relaunched':
    case 'video_completed':
    case 'article_selected':
    case 'job-application_submitted':
    case 'add_to_cart':
      return {
        eventName,
        eventBody: event.data,
      };
    case 'go_to_cart':
      return {
        eventName: 'button_clicked',
        eventBody: {
          button_description: 'go_to_cart',
          business_feature: 'cart_preview',
          currency: event.data.currency,
          value: event.data.value,
          items: event.data.items,
        },
      };
    case 'product_view':
      return {
        eventName: 'view_item',
        eventBody: {
          items: [
            {
              item_name: event.data.name,
              price: event.data.price,
            },
          ],
        },
      };
    case 'promotion_view':
      return {
        eventName: 'view_promotion',
        eventBody: event.data,
      };
    case 'promotion_click':
      return {
        eventName: 'button_clicked',
        eventBody: event.data,
      };
    case 'nav_click':
    case 'link_click':
      return {
        eventName: 'button_clicked',
        eventBody: {
          button_description: event.data.description,
        },
      };
    default:
      return undefined;
  }
}
