import {
  PRODUCT_CONFIGURATOR_FEATURE_TOGGLE,
  SHOP_INTEGRATION_FEATURE_TOGGLE,
} from '~/shared/services/optimizely/constants';
import { FeatureToggles } from '~/shared/services/optimizely/types';

export function hasShopIntegration(
  featureToggles: FeatureToggles = {},
): boolean {
  const hasEnabledPDPToggle = Boolean(
    featureToggles[PRODUCT_CONFIGURATOR_FEATURE_TOGGLE],
  );
  const hasEnabledShopToggle = Boolean(
    featureToggles[SHOP_INTEGRATION_FEATURE_TOGGLE],
  );

  return hasEnabledPDPToggle || hasEnabledShopToggle;
}
