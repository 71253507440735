import get from 'lodash/fp/get';
import includes from 'lodash/fp/includes';
import filter from 'lodash/fp/filter';
import values from 'lodash/fp/values';
import findIndex from 'lodash/fp/findIndex';
import flow from 'lodash/fp/flow';
import isEmpty from 'lodash/fp/isEmpty';

import { ComponentsType, SectionType, SectionsRendererProps } from './types';

import {
  BACKGROUNDS,
  SPACINGS,
  TYPES,
  HIDE_ON,
  BackgroundType,
  SpacingsType,
} from '~/shared/constants/sections';
import { TYPES as FULL_MEDIA_TYPES } from '~/shared/components/FullMedia/constants';
import { VIEWPORTS, ViewportType } from '~/shared/constants';

export const BACKGROUND_MAP: Record<string, BackgroundType> = {
  'Blue': BACKGROUNDS.BLUE,
  'White': BACKGROUNDS.WHITE,
  'Solid — White': BACKGROUNDS.WHITE,
  'Gradient — Lightblue': BACKGROUNDS.LIGHTGREY,
  'Gradient — Lightgrey': BACKGROUNDS.LIGHTGREY,
  'Lightgrey': BACKGROUNDS.LIGHTGREY,
  'Pearl (grey)': BACKGROUNDS.PEARL,
  'Tangerine': BACKGROUNDS.TANGERINE,
  'Coral': BACKGROUNDS.CORAL,
  'Fresh Lime': BACKGROUNDS.FRESH_LIME,
  'Honey Yellow': BACKGROUNDS.HONEY_YELLOW,
  'Orange': BACKGROUNDS.ORANGE,
  'Purple': BACKGROUNDS.PURPLE,
  'Yellow': BACKGROUNDS.YELLOW,
  'Black': BACKGROUNDS.BLACK,
  'Green': BACKGROUNDS.GREEN,
  'Red': BACKGROUNDS.RED,
};

const VIEW_WIDTH_HIDE_ON = {
  [VIEWPORTS.MOBILE]: HIDE_ON.MOBILE,
  [VIEWPORTS.TABLET]: HIDE_ON.TABLET,
  [VIEWPORTS.DESKTOP]: HIDE_ON.DESKTOP,
};

const FULLBLEED_SECTIONS = {
  [TYPES.HERO]: true,
  [TYPES.FULL_MEDIA]: true,
  [TYPES.EXPERIMENT]: true,
  [TYPES.CAREERS_POSITIONS_COMPONENT]: true,
};

const RELATED_SECTIONS = {
  [TYPES.BENEFIT_LIST]: {
    [TYPES.BENEFIT_LIST]: true,
  },
  [TYPES.GALLERY]: {
    [TYPES.GALLERY]: true,
  },
  [TYPES.LONG_TEXT]: {
    [TYPES.LONG_TEXT]: true,
  },
  [TYPES.COLUMN_LAYOUT]: {
    [TYPES.LONG_TEXT]: true,
    [TYPES.GALLERY]: true,
    [TYPES.PRICING_CARDS]: true,
    [TYPES.PRICING_CARDS_BR]: true,
    [TYPES.PRICING_CARDS_WITH_CARD_SCHEMES]: true,
    [TYPES.PRICING_TABLE]: true,
    [TYPES.PRODUCT_PAYMENT_METHODS]: true,
    [TYPES.GENERIC_CAROUSEL]: true,
  },
  all: {
    [TYPES.CARD_SCHEMES]: true,
  },
};

export const getSectionBackground = (
  background: string,
  contentType,
): BackgroundType => {
  if (contentType === TYPES.CALL_TO_ACTION) {
    return BACKGROUNDS.BLUE;
  }
  return BACKGROUND_MAP?.[background] || BACKGROUNDS.WHITE;
};

const isRelatedSection = (firstType, secondType) =>
  !!(
    get([firstType, secondType], RELATED_SECTIONS) ||
    get(['all', secondType], RELATED_SECTIONS)
  );

export const getSpacing = (
  firstSection: SectionType,
  secondSection: SectionType,
): SpacingsType => {
  const firstType = get(['contentType'], firstSection) || '';
  const secondType = get(['contentType'], secondSection) || '';

  if (!firstSection || !secondSection) {
    return SPACINGS.LARGE;
  }

  // sections with different background should have large spacing
  // even when related.
  if (firstSection.background !== secondSection.background) {
    return SPACINGS.LARGE;
  }

  // full bleed sections should have large spacing around them
  if (FULLBLEED_SECTIONS[firstType] || FULLBLEED_SECTIONS[secondType]) {
    return SPACINGS.LARGE;
  }

  // When sections are related (like gallery rows or short text with component)
  // then keep the spacing small
  if (isRelatedSection(firstType, secondType)) {
    return SPACINGS.SMALL;
  }

  return SPACINGS.MEDIUM;
};

const addSpacingsByViewport = (
  sections: SectionType[],
  viewport: ViewportType,
) => {
  // sections included in currently handled view width
  const sectionsForViewports = filter(
    ({ visibility = [] }) =>
      !includes(VIEW_WIDTH_HIDE_ON[viewport], visibility),
    sections,
  );

  return sections.map((currSection) => {
    const index = findIndex(
      (s) => currSection.id === s.id,
      sectionsForViewports,
    );
    // if section is not in visibile in view width, skip
    if (
      index === -1 ||
      (currSection.spacings && currSection.spacings[viewport])
    ) {
      return { ...currSection };
    }

    const spacings = { ...currSection.spacings, [viewport]: {} };
    const prevSection = sectionsForViewports[index - 1];
    const nextSection = sectionsForViewports[index + 1];

    if (
      FULLBLEED_SECTIONS[currSection.contentType] ||
      currSection.type === FULL_MEDIA_TYPES.HERO
    ) {
      spacings[viewport] = {};
    } else if (currSection.contentType === TYPES.MULTISTEP_FORM) {
      if (!isEmpty(prevSection)) {
        spacings[viewport].top = getSpacing(prevSection, currSection);
      }

      if (!isEmpty(nextSection)) {
        spacings[viewport].bottom = getSpacing(currSection, nextSection);
      }
    } else if (currSection.contentType === TYPES.IN_PAGE_NAVIGATION) {
      if (!currSection.alignToTop) {
        spacings[viewport].top = getSpacing(prevSection, currSection);
      }

      spacings[viewport].bottom = getSpacing(currSection, nextSection);
    } else {
      spacings[viewport].top = getSpacing(prevSection, currSection);
      spacings[viewport].bottom = getSpacing(currSection, nextSection);
    }

    return {
      ...currSection,
      spacings,
    };
  });
};

export const addSpacings = (sections: SectionType[]) =>
  values(VIEWPORTS).reduce(addSpacingsByViewport, sections);

export const addBackgrounds = (sections: SectionType[]) =>
  sections.map(({ background, contentType, ...rest }) => ({
    ...rest,
    contentType,
    background: getSectionBackground(background, contentType),
  }));

export const removeNullSections =
  (components: ComponentsType) => (sections: SectionType[]) =>
    filter((section) => components[section.contentType], sections);

export const getSections = (
  sections: SectionType[],
  components: ComponentsType,
): SectionsRendererProps[] =>
  flow([removeNullSections(components), addBackgrounds, addSpacings])(
    sections,
  ) as SectionsRendererProps[];
