export const STOREFRONT_URL_PROD = 'https://store.sumup.com';
export const STOREFRONT_URL_DEV = 'https://storefront-vercel.sam-app.ro';

export const DEFAULT_TAX_RATES: { [countryCode: string]: number } = {
  AT: 20,
  AU: 10,
  BE: 21,
  BG: 20,
  BR: 0,
  CH: 8.1,
  CL: 0,
  CO: 0,
  CY: 19,
  CZ: 21,
  DE: 19,
  DK: 25,
  EE: 22,
  ES: 21,
  FI: 24,
  FR: 20,
  GB: 20,
  GR: 24,
  HR: 25,
  HU: 27,
  IE: 23,
  IT: 22,
  LV: 21,
  LT: 21,
  LU: 17,
  MT: 18,
  NL: 21,
  NO: 25,
  PE: 18,
  PL: 23,
  PT: 23,
  RO: 19,
  SE: 25,
  SK: 20,
  SI: 22,
};
