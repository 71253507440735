// TEMP WORKAROUND
// intercept and prevent wasteful /_next/data/*.json request until Next.js issue is resolved
// https://github.com/vercel/next.js/discussions/38414
// https://github.com/vercel/next.js/issues/40611
//
// NOTE: This workaround will be unnecessary once all pages are switched to `getServerSideProps` instead of `getInitialProps`
// https://sumupteam.atlassian.net/wiki/spaces/DEV/pages/22497460348/Website-backend+page+API+replacement#1.-Maximum-separation

import { pathToRegexp } from 'path-to-regexp';

import {
  MANUAL_AIR_PATH,
  MANUAL_3G_PATH,
  PLUS_PATH,
  FEES_PATH,
  CONTACT_PATH,
} from '~/domains/product-manuals/constants/pathnames';
import { CAREERS_PATH } from '~/shared/constants/pathnames';

const NEXT_DATA_PREFIX = '/_next/data';
const NEXT_DATA_PATH = `${NEXT_DATA_PREFIX}(.*).json*`;

const MIGRATED_TO_GET_SERVER_SIDE_PROPS_PATHS = [
  `${CAREERS_PATH}(.*)`,
  MANUAL_AIR_PATH,
  MANUAL_3G_PATH,
  PLUS_PATH,
  FEES_PATH,
  CONTACT_PATH,
].map((migratedPath) => `${NEXT_DATA_PREFIX}(.*)${migratedPath}.json*`);

export function shouldPreventNextDataRequestTo(url: RequestInfo | URL) {
  const nextDataPathRegex = pathToRegexp(NEXT_DATA_PATH);
  const isNextDataRequest = nextDataPathRegex.test(url.toString());

  if (isNextDataRequest) {
    const isMigratedPath = MIGRATED_TO_GET_SERVER_SIDE_PROPS_PATHS.some(
      (migratedPath) => {
        const migratedPathRegex = pathToRegexp(migratedPath);
        return migratedPathRegex.test(url.toString());
      },
    );
    return !isMigratedPath;
  }

  return false;
}

export function preventWastefulNextDataRequests() {
  if (process.browser) {
    const { fetch: originalFetch } = window;
    window.fetch = async (...args) => {
      const [url] = args;
      if (shouldPreventNextDataRequestTo(url)) {
        return Promise.reject();
      }
      return originalFetch(...args);
    };
  }
}
