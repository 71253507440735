/*
 * Optimizely experiments data storage and related data processing utils.
 */
import { experimentsParser } from '@sumup/optimizely-full-stack';

import { EXPERIMENT_PREVIEW_PARAM } from './constants';
import * as featureToggleParser from './feature-toggle-parser';
import { FeatureToggles } from './types';

import { VERCEL_CDN_CACHE_PARAMS } from '~/shared/constants';
import { RequestType } from '~/shared/providers/RequestContext/RequestContext';

let inMemoryExperiments = '';
let inMemoryFeatureToggles = '';

// cdn cache from vercel is used on server side rendering during the initial page load
// in-memory is used to persist the values during SPA navigation
export function getOptimizelyData(query: RequestType['query'] = {}) {
  const previewQueryExperimentsStr = getOptimizelyPreviewDataFromQuery(query);
  const cdnCacheQueryExperimentsStr = getQueryValue(
    query[VERCEL_CDN_CACHE_PARAMS.AB_EXPERIMENTS],
  );
  const experimentsStr =
    inMemoryExperiments ||
    previewQueryExperimentsStr ||
    cdnCacheQueryExperimentsStr;
  const experiments = experimentsParser.parse(experimentsStr);

  const cdnCacheFeatureTogglesStr = getQueryValue(
    query[VERCEL_CDN_CACHE_PARAMS.FEATURE_TOGGLES],
  );
  const featureTogglesStr = inMemoryFeatureToggles || cdnCacheFeatureTogglesStr;
  const featureToggles = featureToggleParser
    .parse(featureTogglesStr)
    .reduce<FeatureToggles>((acc, featureToggleKey) => {
      if (featureToggleKey) {
        acc[featureToggleKey] = true;
      }
      return acc;
    }, {});

  return {
    experiments,
    featureToggles,
  };
}

// allow preview on variations with query param ?optimizelyExperiment=ruleKey:variationA
export function getOptimizelyPreviewDataFromQuery(
  query: RequestType['query'] = {},
): string {
  const optimizelyExperiments = query[EXPERIMENT_PREVIEW_PARAM];

  if (!optimizelyExperiments) {
    return undefined;
  }

  if (Array.isArray(optimizelyExperiments)) {
    return optimizelyExperiments.join('&');
  }

  return optimizelyExperiments;
}

export function setInMemoryExperiments(
  activatedExperiments: string | string[],
) {
  inMemoryExperiments = getQueryValue(activatedExperiments);
}

export function setInMemoryFeatureToggles(
  activatedFeatureToggles: string | string[],
) {
  inMemoryFeatureToggles = getQueryValue(activatedFeatureToggles);
}

function getQueryValue(value: string | string[]): string {
  return Array.isArray(value) ? value[0] : value;
}
