import { pathToRegexp } from 'path-to-regexp';

import {
  MANUAL_AIR_PATH,
  MANUAL_3G_PATH,
  PLUS_PATH,
  FEES_PATH,
  CONTACT_PATH,
} from '~/domains/product-manuals/constants/pathnames';
import {
  CAREERS_PATH,
  CONTENT_PREVIEW_PATH,
} from '~/shared/constants/pathnames';
import { addTrailingSlash, addLeadingSlash } from '~/shared/services/url';

export const NON_LOCALIZED_PATHNAMES = [
  `${CAREERS_PATH}*`,
  `${CONTENT_PREVIEW_PATH}*`,
];

export const NON_LOCALIZED_PATHNAMES_ON_EDGE = [
  ...NON_LOCALIZED_PATHNAMES,

  // Edge middleware has dedicated check because of product manual pages
  MANUAL_AIR_PATH,
  MANUAL_3G_PATH,
  PLUS_PATH,
  FEES_PATH,
  CONTACT_PATH,
];

const checkPathname = (
  pathname: string = '',
  nonLocalizedPathnames: string[] = NON_LOCALIZED_PATHNAMES,
): boolean => {
  const targetPathname = addTrailingSlash(addLeadingSlash(pathname));
  return nonLocalizedPathnames.some((nonLocalizedPathname) => {
    const regexp = pathToRegexp(nonLocalizedPathname);
    const match = regexp.test(targetPathname);
    return match;
  });
};

export const isNonLocalizedPathname = (pathname: string = ''): boolean =>
  checkPathname(pathname, NON_LOCALIZED_PATHNAMES);

export const isNonLocalizedPathnameOnEdge = (pathname: string = ''): boolean =>
  checkPathname(pathname, NON_LOCALIZED_PATHNAMES_ON_EDGE);
