import { NextRequest } from 'next/server';

import { isNonLocalizedPathnameOnEdge } from '../pathnames';

import {
  DEFAULT_LOCALE_KEY,
  DEFAULT_LOCALE_ONE_DOMAIN,
} from '~/shared/constants/locales';
import { SUMUP_VERCEL_SUFFIX } from '~/shared/constants/websites';
import * as ENVIRONMENTS from '~/shared/constants/environments';
import {
  ONE_DOMAIN_HOSTS,
  SUMUP_DOMAINS_REGEX,
  STAGING_DOMAIN_REGEX,
  THETA_DOMAIN_REGEX,
} from '~/shared/constants/one-domain';
import { getLocaleFromHostname } from '~/shared/services/edge/hostname-locale';
import { getCustomNextUrl } from '~/shared/services/edge/custom-next-url';

// Decision record - https://github.com/sumup/website/blob/master/docs/adr/20240301-manual-nextjs-localization.md

/**
 * PROD sumup.de/foo/ -> www.sumup.com/de-de/foo/
 *      sumup.com/de-DE/foo/ -> www.sumup.com/de-de/foo/
 *      sumup.com/de-de/foo/ -> www.sumup.com/de-de/foo/
 *      www.sumup.com/foo -> www.sumup.com/en-us/foo/
 *
 * STAGING de.sam-app.ro/foo/ -> www.sam-app.ro/de-de/foo/
 *         sam-app.ro/de-DE/foo/ -> www.sam-app.ro/de-de/foo/
 *         sam-app.ro/de-de/foo/ -> www.sam-app.ro/de-de/foo/
 *         www.sam-app.ro/foo/ -> www.sam-app.ro/en-us/foo/
 *
 * THETA de-theta.sam-app.ro/foo/ -> theta.sam-app.ro/de-de/foo/
 *       theta.ro/de-DE/foo/ -> theta.sam-app.ro/de-de/foo/
 *       theta.sam-app.ro/foo/ -> theta.sam-app.ro/en-us/foo/
 *
 * VERCEL LINKS website-xxx.sumup-vercel.app/foo/?hostname=de.sam-app.ro -> website-xxx.sumup-vercel.app/de-de/foo/
 *              website-xxx.sumup-vercel.app/de-DE/foo/ -> website-xxx.sumup-vercel.app/de-de/foo/
 *              website-xxx.sumup-vercel.app/foo/ -> website-xxx.sumup-vercel.app/en-us/foo/
 *
 * DEVELOPMENT localhost/foo/?hostname=sumup.de -> localhost/de-de/foo/
 *             localhost/de-DE/foo/ -> localhost/de-de/foo/
 *             localhost/foo/ -> localhost/en-us/foo/
 */

export function getOneDomainRedirectUrl(req: NextRequest): string | undefined {
  const requestUrl = getCustomNextUrl(req);
  const {
    host,
    searchParams,
    pathname,
    originalPathname,
    locale: nextLocale,
  } = requestUrl;
  const query = Object.fromEntries(searchParams);

  // check if request comes from one domain host - www.sam-app.ro, www.sumup.com or theta.sam-app.ro
  if (isOneDomainHost(host, nextLocale)) {
    // keep /careers/ and child pages free from prefix locale
    if (isNonLocalizedPathnameOnEdge(pathname)) {
      return undefined;
    }

    // redirect to /en-us/ prefix when pathname misses locale in path
    // https://nextjs.org/docs/advanced-features/i18n-routing#prefixing-the-default-locale
    if (nextLocale === DEFAULT_LOCALE_KEY) {
      requestUrl.pathname = `/${DEFAULT_LOCALE_ONE_DOMAIN}${pathname}`;

      return requestUrl.toString();
    }

    // redirect to lowercase /xx-xx/ from mixedcase locale path /xx-XX/
    const localizedPathname = `/${nextLocale}${pathname}`;
    const hasCorrectLocalizedPathname = originalPathname === localizedPathname;
    if (!hasCorrectLocalizedPathname) {
      requestUrl.pathname = localizedPathname;

      return requestUrl.toString();
    }

    return undefined;
  }

  // check if it is possible to get one domain host for requested host
  // applicable only for old locale hosts - sumup.xx, xx.sam-app.ro, xx-theta.sam-app.ro
  const oneDomainHost = getOneDomainHost(host, query?.hostname);
  if (!oneDomainHost) {
    return undefined;
  }

  requestUrl.host = oneDomainHost;

  if (!nextLocale || nextLocale === DEFAULT_LOCALE_KEY) {
    // retrieve locale /xx-xx/ pathname prefix from old locale hosts
    const detectedLocale = getLocaleFromHostname(host, query?.hostname);
    if (detectedLocale) {
      requestUrl.pathname = `/${detectedLocale.toLowerCase()}${pathname}`;
    }
  }

  searchParams.delete('hostname');

  return requestUrl.toString();
}

export function getOneDomainHost(
  host: string,
  queryHostname?: string,
): string | undefined {
  const isVercelOrLocalHost =
    host.includes(SUMUP_VERCEL_SUFFIX) || host.includes('localhost');
  if (isVercelOrLocalHost) {
    if (queryHostname) {
      return mapOneDomainFromHost(queryHostname) ? host : undefined;
    }
    return host;
  }

  return mapOneDomainFromHost(host);
}

function mapOneDomainFromHost(host: string): string | undefined {
  // theta.sam-app.ro       YES
  // uk-theta.sam-app.ro    YES
  // de-ch-theta.sam-app.ro YES
  const isThetaHost = THETA_DOMAIN_REGEX.test(host);
  if (isThetaHost) {
    return ONE_DOMAIN_HOSTS[ENVIRONMENTS.THETA];
  }

  // sam-app.ro           YES
  // www.sam-app.ro       YES
  // de.sam-app.ro        YES
  // ch-de.sam-app.ro     YES
  // supportuk.sam-app.ro  NO
  const isStagingHost = STAGING_DOMAIN_REGEX.test(host);
  if (isStagingHost) {
    return ONE_DOMAIN_HOSTS[ENVIRONMENTS.STAGING];
  }

  // sumup.com           YES
  // sumup.co.uk         YES
  // sumup.com.br        YES
  // ch-de.sumup.com     YES
  // www.sumup.com       YES
  // fr.sumup.be         YES
  // supportuk.sam-app.ro NO
  // help.sumup.com       NO
  const isProdHost = SUMUP_DOMAINS_REGEX.test(host);
  if (isProdHost) {
    return ONE_DOMAIN_HOSTS[ENVIRONMENTS.PRODUCTION];
  }

  return undefined;
}

function isOneDomainHost(host: string, nextLocale?: string): boolean {
  const isVercelOrLocalHost =
    host.includes(SUMUP_VERCEL_SUFFIX) || host.includes('localhost');

  if (isVercelOrLocalHost) {
    return nextLocale !== DEFAULT_LOCALE_KEY;
  }

  return !!Object.values(ONE_DOMAIN_HOSTS).find(
    (defaultHostname) => defaultHostname === host,
  );
}
