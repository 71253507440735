/*
 * Shared utils and functions used in both Optimizely SDK clients (browser and edge servers).
 */

import fetch from 'isomorphic-unfetch';

import { STATIC_DATA_APIS_ONE_DOMAIN } from './constants';
import {
  DatafileResponse,
  Experiment,
  ExperimentsMap,
  Datafile,
  FetchResponse,
} from './types';

import * as ENVIRONMENTS from '~/shared/constants/environments';
import { findLocale } from '~/shared/services/one-domain';

export function getDatafileUrl(environment?: string): string {
  const api = STATIC_DATA_APIS_ONE_DOMAIN;
  return environment === ENVIRONMENTS.PRODUCTION ||
    environment === ENVIRONMENTS.THETA
    ? api.production
    : api.staging;
}
export async function loadDatafile(
  targetPathname: string,
  fileApiUrl: string,
  _locale: string = undefined,
): Promise<DatafileResponse> {
  const {
    experimentsMap = {},
    datafile = {},
  }: { experimentsMap: ExperimentsMap; datafile: Datafile } = await fetch(
    fileApiUrl,
  )
    .then((res) => res.json() as unknown as FetchResponse)
    .catch(() => ({ experimentsMap: {}, datafile: {} }));

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [, _pathLocale] = targetPathname && targetPathname.split('/');
  const locale = _locale || _pathLocale;
  const prefix = `/${locale.toLowerCase()}`;

  const localeIsInPath = findLocale(_pathLocale);

  const finalPathname = localeIsInPath
    ? targetPathname
    : `${prefix}${targetPathname}`;
  const websiteKey = `${prefix}/*`;

  const experimentMapKeys = Object.keys(experimentsMap);
  const experimentsMapWebsiteKey = experimentMapKeys.find(
    (experimentMapKey) => experimentMapKey === websiteKey,
  );

  const websiteExperimentKeys = experimentsMap[experimentsMapWebsiteKey] || [];
  const routeKey = experimentMapKeys.find(
    (experimentMapKey) => experimentMapKey === finalPathname,
  );

  const routeExperimentKeys = experimentsMap[routeKey] || [];
  const pageExperimentKeys = Array.from(
    new Set([...websiteExperimentKeys, ...routeExperimentKeys]),
  );

  return { pageExperimentKeys, datafile, experimentsMap };
}

export function filterOutNotRunningExperiments(
  experimentsMap: ExperimentsMap,
  storedExperiments: Experiment[],
): Experiment[] {
  const allRunningExperimentsKeys = Array.from(
    new Set(Object.values(experimentsMap).flat()),
  );
  const onlyRunningStoredExperiments = storedExperiments.filter((experiment) =>
    allRunningExperimentsKeys.includes(experiment.experimentKey),
  );

  return onlyRunningStoredExperiments || [];
}
