import { VERCEL_CDN_CACHE_PARAMS } from '~/shared/constants';
import { RequestType } from '~/shared/providers/RequestContext/RequestContext';
import {
  PRC_COUPON_FEATURE_TOGGLE,
  PRODUCT_CONFIGURATOR_FEATURE_TOGGLE,
  SHOP_INTEGRATION_FEATURE_TOGGLE,
} from '~/shared/services/optimizely/constants';

export const PAGE_TYPES_WITHOUT_SHOP_INTEGRATION = [
  'careersPage',
  'careersPosition',
  'prospectingPage',
];

export function omitShopIntegrationFromPages(request?: RequestType): void {
  const pageType = request?.contentType;
  const featureTogglesParamValue = request?.query?.[
    VERCEL_CDN_CACHE_PARAMS.FEATURE_TOGGLES
  ] as string;
  const hasFeatureTogglesParam = Boolean(featureTogglesParamValue);
  const shouldOmit =
    hasFeatureTogglesParam &&
    PAGE_TYPES_WITHOUT_SHOP_INTEGRATION.includes(pageType);
  if (shouldOmit) {
    request.query[VERCEL_CDN_CACHE_PARAMS.FEATURE_TOGGLES] =
      featureTogglesParamValue
        .replace(SHOP_INTEGRATION_FEATURE_TOGGLE, '')
        .replace(PRC_COUPON_FEATURE_TOGGLE, '')
        .replace(PRODUCT_CONFIGURATOR_FEATURE_TOGGLE, '');
  }
}
