export const FEATURE_TOGGLE_QUERY_DELIMITER = '|';

export function serialize(featureToggles: string[]): string {
  if (!featureToggles?.length) {
    return '';
  }
  if (!Array.isArray(featureToggles)) {
    return '';
  }

  return encodeURIComponent(
    featureToggles.join(FEATURE_TOGGLE_QUERY_DELIMITER),
  );
}

export function parse(featureToggles: string): string[] {
  if (!featureToggles?.trim()?.length) {
    return [];
  }
  return decodeURIComponent(featureToggles)
    ?.split(FEATURE_TOGGLE_QUERY_DELIMITER)
    ?.filter((featureToggle) => !!featureToggle);
}
