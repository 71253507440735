import * as url from 'url';
import { ParsedUrlQuery } from 'querystring';

import isServer from '~/shared/util/is-server';
import { RequestType } from '~/shared/providers/RequestContext/RequestContext';

export type URLLikeObject = {
  protocol?: string;
  href?: string;
  origin?: string;
  hostname?: string;
  host?: string;
  query?: ParsedUrlQuery;
  searchParams?: URLSearchParams;
  hash?: string;
  pathname?: string;
  port?: string;
  search?: string;
  auth?: string;
  password?: string;
  username?: string;
  toJSON?: () => string;
};

export interface CustomParsedUrl extends URL {
  query?: URLLikeObject['query'];
}

export function isPhoneOrEmail(urlObj: URLLikeObject) {
  return ['mailto', 'tel'].some((protocol) =>
    urlObj?.protocol?.includes(protocol),
  );
}

export const parse = (fullUrl: string, hostname?: string): CustomParsedUrl => {
  const emptyUrl = {
    protocol: '',
    pathname: '',
    hash: '',
    host: '',
    href: '',
    hostname: '',
    port: '',
    search: '',
    searchParams: new URLSearchParams(),
    query: {},
    origin: '',
    password: '',
    username: '',
    toJSON: () => '',
  };

  if (!fullUrl) {
    return emptyUrl;
  }

  let finalUrl: CustomParsedUrl;

  try {
    finalUrl = new URL(
      fullUrl,
      isServer ? hostname : hostname || window.location.origin,
    );
    finalUrl.query = Object.fromEntries(finalUrl?.searchParams) || {};
    return finalUrl;
  } catch (e) {
    return emptyUrl;
  }
};

export const addTrailingSlash = (path: string) => {
  const hasTrailingSlash = path.substr(-1) === '/';
  const isFile = path.includes('.');

  return isFile || hasTrailingSlash ? path : `${path}/`;
};

export const addLeadingSlash = (path: string) => {
  const hasLeadingSlash = path.substr(0, 1) === '/';

  return hasLeadingSlash ? path : `/${path}`;
};

export const removeLeadingSlash = (path: string) => {
  if (path === '/') {
    return '/';
  }
  return path?.startsWith('/') ? path.substr(1, path.length) : path;
};

export const removeTrailingSlash = (path: string) => {
  if (path === '/') {
    return '/';
  }
  return path?.endsWith('/') ? path.substr(0, path.length - 1) : path;
};

export const format = (
  urlObj: URLLikeObject,
  addSlash: boolean = true,
): string => {
  const { pathname } = urlObj;
  if (!pathname || !addSlash) {
    return url.format(urlObj);
  }
  const withLeadingSlash = addLeadingSlash(pathname);
  const withTrailingSlash = addTrailingSlash(withLeadingSlash);
  return url.format({ ...urlObj, pathname: withTrailingSlash });
};

export const formatSlug = (path: string) => {
  const withoutLeadingSlash = removeLeadingSlash(path);
  const withoutTrailingSlash = removeTrailingSlash(withoutLeadingSlash);
  return withoutTrailingSlash;
};

export const isInternal = (urlObj: URLLikeObject, request: RequestType) => {
  const { host } = urlObj;
  if (isPhoneOrEmail(urlObj)) {
    return false;
  }
  if (!host) {
    return true;
  }
  return host === request?.host;
};

export const isAbsolute = (urlString: string) => {
  const protocols = ['http', 'mailto', 'tel', '//'];
  return protocols.some((p) => urlString?.startsWith(p));
};

export const stripIndex = (path: string) => {
  const indexPattern = '/index.htm';
  return path?.includes(indexPattern)
    ? path?.substring(0, path?.indexOf(indexPattern))
    : path;
};

export function isCurrent(urlObj: URLLikeObject, request: RequestType) {
  const isOnlyHash = urlObj?.href?.startsWith('#');
  const matchesHost = !urlObj?.host || urlObj?.host === request?.host;
  const matchesPathname =
    formatSlug(urlObj?.pathname) === formatSlug(request?.pathname);
  return isOnlyHash || (matchesHost && matchesPathname);
}

export function cleanApiParams(query: ParsedUrlQuery): ParsedUrlQuery {
  const ALLOWED_API_QUERY_PARAMS: { [param: string]: boolean } = {
    // for internal developer purposes
    chostname: true,
    noRedirect: true,
    statusCode: true,
    cacheTypes: true,
    format: true,
    invalidations: true,
    locale: true,
    country: true,
    hostname: true,
    useNewBackend: true,
    gh_jid: true,
    gh_src: true,
    entryId: true,
    a_aid: true, // for affiliate redirects
    shopIntegration: true,
    prcCouponsIntegration: true,
    prc: true,
  };

  return Object.keys(query)
    .filter((queryKey) => ALLOWED_API_QUERY_PARAMS[queryKey])
    .reduce<ParsedUrlQuery>((acc, allowedQueryKey) => {
      acc[allowedQueryKey] = query[allowedQueryKey];
      return acc;
    }, {});
}

export function getSecondLevelDomain(host = '') {
  const sumupHostsRegexp =
    /^(.+\.)?(sumup|sam-app)\.[a-zA-Z]{2,3}(\.[a-zA-Z]{2})?$/;

  if (!sumupHostsRegexp.test(host)) {
    return undefined;
  }

  const sumupHosts = ['sumup', 'sam-app'];
  const [subDomain, ...parts] = host.split('.');

  if (!sumupHosts.includes(subDomain)) {
    return parts.join('.');
  }

  return host;
}
